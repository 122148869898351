<template>
  <BaseCard>
    <template #content>
      <BasePhoto
        :disabled="!isCanLoad($t('base.images')) && !isCanInteractionPassport"
        :model-id="value.properties.id"
        model-name="passport"
        name-object-img="passportImages"
        v-model="detail"
        @input="$emit('input', $event)"
        with-description
      />
    </template>
  </BaseCard>
</template>

<script>
import BasePhoto from '@/components/base/BasePhoto'
import BaseCard from '@/components/base/BaseCard'
import userInteraction from '@/components/mixins/userInteraction'
import watchValue from '@/components/mixins/watchValue'

export default {
  name: 'PassportPhotos',
  components: { BasePhoto, BaseCard },
  mixins: [userInteraction, watchValue],
  props: {
    value: Object
  },
  data () {
    return {
      detail: this.value
    }
  }
}
</script>
